<template>
  <div class="member">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"/>
    <MemberReserveList
      :Classification="Classification"
      :showAppointmentData="showAppointmentData"
      v-on:updateAppointment="getAllAppointment"
      :isTaiwanId="isTaiwanId" />
  </div>
</template>

<script>
import '@/assets/scss/news.scss';
import BannerKv from '@/components/BannerKv.vue';
import MemberReserveList from '@/components/member/MemberReserveList.vue';
import { isNationalIdentificationNumberValid } from 'taiwan-id-validator';

import { checkAppointment, getMemberProfile } from '@/lib/public';

export default {
  name: 'MemberHome',
  components: {
    BannerKv,
    MemberReserveList,
  },
  data() {
    return {
      isTaiwanId: false,
      memberProfile: '',
      BannerKvData: {
        titleEn: 'WELCOME',
        titleZh: `${this.$cookies.get('name')} 貴賓，您好`,
      },
      Classification: [
        {
          path: '/memberhome',
          linkName: this.$t('myregistration'),
        },
        {
          path: '/memberdata',
          linkName: this.$t('myInfo'),
        },
      ],
      showAppointmentData: [],
    };
  },
  methods: {
    getAllAppointment() {
      console.log(this.$cookies.get('api_token'));
      this.showAppointmentData = [];
      if (this.$cookies.get('api_token')) {
        checkAppointment(
          this.$cookies.get('api_token'),
          (response) => {
            console.log(response.data.data);
            response.data.data.forEach((elem) => {
              this.showAppointmentData.push(elem);
            });
          },
          (response) => {
            this.$customSWAL({ icon: 'error', title: response });
          },
        );
      } else {
        this.$customSWAL(
          {
            icon: 'error',
            title: this.$t('pleaseLogin'),
            thenFunc: () => {
              this.$router.push('/Login');
            },
          },
        );
      }
    },
    getThisMemberProfile() {
      console.log(this.$cookies.get('api_token'));
      this.showAppointmentData = [];
      if (this.$cookies.get('api_token')) {
        getMemberProfile(
          this.$cookies.get('api_token'),
          (response) => {
            console.log(response.data.data);
            this.memberProfile = response.data.data;
            if (isNationalIdentificationNumberValid(this.memberProfile.account)) {
              this.isTaiwanId = true;
            }
          },
          (response) => {
            this.$customSWAL({ icon: 'error', title: response });
          },
        );
      } else {
        this.$customSWAL({
          icon: 'error',
          title: this.$t('pleaseLogin'),
          thenFunc: () => { this.$router.push('/Login'); },
        });
      }
    },
  },
  mounted() {
    this.getAllAppointment();
    this.getThisMemberProfile();
  },
};
</script>
