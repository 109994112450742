<template>
  <section id="member_reserve_list">
    <div class="container w1300">
      <div class="fliter_outer">
        <MenuClassificationFilter
          :Classification="Classification"/>
      </div>
      <div class="list_outer">
        <div class="reserve_list" v-if="showAppointmentData.length > 0">
          <div class="reserve_item"
            v-for="(item, key) in showAppointmentData"
            :key="key">
            <p class="reserve_date">{{$t('registrationDate')}} | {{item.日期}}</p>
            <div class="item_info">
              <div class="cancel_area">
                <button type="button" class="cancel txt-white small"
                  @click="openCancelReserveLightbox(item.預掛識別碼);">
                  {{$t('cancelReserve')}}
                </button>
              </div>
              <table>
                <tr>
                  <td>
                    <p class="small"><span class="txt-dark_gray">{{$t('registrationCategory')}} | </span>{{item.科別}}</p>
                  </td>
                  <td>
                    <p class="small"><span class="txt-dark_gray">{{$t('registrationTime')}} | </span>{{item.班別}}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p class="small"><span class="txt-dark_gray">{{$t('room')}} | </span>{{item.診別}}</p>
                  </td>
                  <td>
                    <p class="small"><span class="txt-dark_gray">{{$t('doctor')}} | </span>
                    <template  v-if="getLanguage === 'en-US'">
                      {{item.醫師名}}
                    </template>
                    <template v-else>
                      {{item.醫師名}}
                      {{$t('doctor')}}
                    </template>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p class="small"><span class="txt-dark_gray">{{$t('yourName')}} | </span>{{`${$cookies.get('name')}`}}</p>
                  </td>
                  <td>
                    <p class="small"><span class="txt-dark_gray">
                      {{$t('number')}} | </span>
                      <template v-if="getLanguage !== 'en-US'">
                        {{item.掛號序號}}{{$t('num')}}
                      </template>
                      <template v-else>
                        {{item.掛號序號}}
                      </template>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p v-if="isTaiwanId" class="small"><span class="txt-dark_gray">{{$t('uid')}} | </span>{{`${$cookies.get('account')}`}}</p>
                    <p v-else class="small"><span class="txt-dark_gray">{{$t('pidAndPassportNum')}} | </span>{{`${$cookies.get('account')}`}}</p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="reserve_list" v-else>
          <p class="no_message">
            {{$t('noRigistration')}}
          </p>
        </div>
      </div>
    </div>

    <div id="cancel_reserve_lightbox"
      v-show="cancelReserveLightboxOpen">
      <div class="cover" @click="clickCloseCancelReserveLightbox()"></div>
      <div class="box">
        <div class="text txt-center">
          <h5 class="txt-bold">
            {{$t('cancelReserve')}}
            <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
          </h5>
          <div class="reserve_data txt-left" v-if="showAppointmentDataForCancel">
            <p>
              <span class="txt-dark_gray">{{$t('registrationDate')}} | </span>{{showAppointmentDataForCancel.日期}} (三)
            </p>
            <p>
              <span class="txt-dark_gray">{{$t('registrationTime')}} | </span>{{showAppointmentDataForCancel.班別}}
            </p>
            <p>
              <span class="txt-dark_gray">{{$t('registrationCategory')}} | </span>{{showAppointmentDataForCancel.科別}}
            </p>
            <p>
              <span class="txt-dark_gray">{{$t('doctor')}} | </span>
              <template  v-if="getLanguage === 'en-US'">
                  {{$t('doctor')}} {{showAppointmentDataForCancel.醫師名}}
              </template>
              <template v-else>
                {{showAppointmentDataForCancel.醫師名}} {{$t('doctor')}}
              </template>
            </p>
            <p>
              <span class="txt-dark_gray">{{$t('room')}} | </span>{{showAppointmentDataForCancel.診別}}
            </p>
            <p>
              <span class="txt-dark_gray">{{$t('number')}} | </span>
              <template v-if="getLanguage !== 'en-US'">
                {{showAppointmentDataForCancel.掛號序號}}
                {{$t('num')}}
              </template>
              <template v-else>
                {{showAppointmentDataForCancel.掛號序號}}
              </template>
            </p>
            <p>
              <span class="txt-dark_gray">{{$t('yourName')}} | </span>{{`${$cookies.get('name')}`}}
            </p>
            <p v-if="isTaiwanId">
              <span class="txt-dark_gray">{{$t('uid')}} | </span>{{`${$cookies.get('account')}`}}
            </p>
            <p v-else>
              <span class="txt-dark_gray">{{$t('pidAndPassportNum')}} | </span>{{`${$cookies.get('account')}`}}
            </p>
          </div>
          <button type="button"
            class="btn p"
            @click="clickCancelAppointment(codeForCancel);">
            {{$t('comfirmCancel')}}
          </button>
        </div>
      </div>
    </div>

    <div id="cancel_reserve_message_lightbox"
      v-show="cancelReservemMessageLightboxOpen">
      <div class="cover" @click="clickCloseCancelReserveMessageLightbox()"></div>
      <div class="box">
        <div class="text txt-center">
          <img class="deco_check" src="@/assets/img/member/carry_out.svg" alt="">
          <h5 class="txt-bold">
            {{$t('cancelSucsess')}}
            <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
          </h5>
          <button type="button"
            class="btn p"
            @click="clickCloseCancelReserveMessageLightbox()">
            {{$t('complete')}}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import '@/assets/scss/member.scss';
import MenuClassificationFilter from '@/components/MenuClassificationFilter.vue';

import { cancelAppointment, returnZhDay } from '@/lib/public';
import { mapGetters } from 'vuex';

export default {
  name: 'MemberReserveList',
  components: {
    MenuClassificationFilter,
  },
  props: {
    Classification: Array,
    showAppointmentData: Array,
    isTaiwanId: Boolean,
  },
  computed: {
    ...mapGetters([
      'getLanguage',
    ]),
  },
  data() {
    return {
      cancelReserveLightboxOpen: false,
      cancelReservemMessageLightboxOpen: false,
      showAppointmentDataForCancel: null,
      codeForCancel: null,
    };
  },
  watch: {
    codeForCancel(val) {
      // const temp = val;
      this.showAppointmentData.forEach((elem) => {
        if (val === elem.預掛識別碼) {
          this.showAppointmentDataForCancel = elem;
        }
      });
    },
    showAppointmentData() {
      this.showAppointmentData.forEach((form, i) => {
        switch (form.科別) {
          case '生殖醫學':
            this.showAppointmentData[i].科別 = this.$t('ivf');
            break;
          case '中醫':
            this.showAppointmentData[i].科別 = this.$t('tcm');
            break;
          default:
            break;
        }
        switch (form.班別) {
          case '上午':
            this.showAppointmentData[i].班別 = this.$t('am');
            break;
          case '下午':
            this.showAppointmentData[i].班別 = this.$t('pm');
            break;
          case '晚上':
            this.showAppointmentData[i].班別 = this.$t('night');
            break;
          default:
            break;
        }
        switch (form.班別) {
          case '上午':
            this.showAppointmentData[i].班別 = this.$t('am');
            break;
          case '下午':
            this.showAppointmentData[i].班別 = this.$t('pm');
            break;
          case '晚上':
            this.showAppointmentData[i].班別 = this.$t('night');
            break;
          case '晚間':
            this.showAppointmentData[i].班別 = this.$t('betweenNight');
            break;
          default:
            break;
        }
        switch (form.診別) {
          case '一診':
            this.showAppointmentData[i].診別 = this.$t('roomOne');
            break;
          case '二診':
            this.showAppointmentData[i].診別 = this.$t('roomTwo');
            break;
          case '三診':
            this.showAppointmentData[i].診別 = this.$t('roomThree');
            break;
          case '四診':
            this.showAppointmentData[i].診別 = this.$t('roomFour');
            break;
          case '五診':
            this.showAppointmentData[i].診別 = this.$t('roomFive');
            break;
          case '六診':
            this.showAppointmentData[i].診別 = this.$t('roomSix');
            break;
          case '七診':
            this.showAppointmentData[i].診別 = this.$t('roomSeven');
            break;
          case '八診':
            this.showAppointmentData[i].診別 = this.$t('roomEight');
            break;
          default:
            break;
        }
      });
    },
  },
  methods: {
    returnZhDay,
    openCancelReserveLightbox(code) {
      this.cancelReserveLightboxOpen = true;
      this.codeForCancel = code;
    },
    clickCloseCancelReserveLightbox() {
      this.cancelReserveLightboxOpen = false;
    },
    clickCloseCancelReserveMessageLightbox() {
      this.cancelReservemMessageLightboxOpen = false;
    },
    clickCancelAppointment(code) {
      cancelAppointment(
        this.$cookies.get('api_token'),
        { appointment_code: code },
        (response) => {
          console.log(response);
          this.$emit('updateAppointment');
          this.cancelReserveLightboxOpen = false;
          this.cancelReservemMessageLightboxOpen = true;
        },
        (message) => {
          this.$customSWAL(
            {
              icon: 'error',
              title: message,
              thenFunc: () => {
                this.cancelReserveLightboxOpen = false;
              },
            },
          );
        },
      );
    },
  },
  mounted() {
    console.log(this.showAppointmentData);
  },
};
</script>
